export function styleLoad({ styleBuffer, styleHref, tagId }) {
  const [head] = document.getElementsByTagName('head');
  const linkElement = document.createElement('link');
  const styleData = styleBuffer ? `data:text/css;charset=UTF-8,${encodeURIComponent(styleBuffer)}` : styleHref;
  if (tagId) {
    linkElement.id = tagId;
  }
  linkElement.setAttribute('rel', 'stylesheet');
  linkElement.setAttribute('type', 'text/css');
  linkElement.setAttribute('href', styleData);
  head.appendChild(linkElement);
}

export function scriptLoad({ src, type, callback }) {
  const [head] = document.getElementsByTagName('head');
  const scriptTag = document.createElement('script');

  if (type) {
    scriptTag.type = type;
  }

  scriptTag.src = `${src}`;

  if (callback) {
    scriptTag.onload = callback;
  }

  head.appendChild(scriptTag);
}
