const { $ } = window;
const staticHostName = new URL(document.currentScript.src).hostname;

import { styleLoad, scriptLoad } from '@/_modules/utils/utils/utils.handler';

export const include = new (function () {
  this.appendTemplate = function (template) {
    if (!document.querySelector(`script[src^="https://${staticHostName}/jsn/jaSwiperAutoload.js"]`)) {
      scriptLoad({ src: `https://${staticHostName}/jsn/jaSwiperAutoload.js?v=${new Date().getTime()}` });
    }

    $.each(template, function (index, item) {
      if ($(item.target).length) {
        $.ajax({
          url: item.url,
          async: false,
          type: 'GET',
          withCredentials: true,
          xhrFields: { withCredentials: true },
          success(data) {
            const $data = $(data);
            $(item.target).html($data);
            $data.unwrap();

            const swiperLoadFunction = window?.joongang?.jaSwiperAutoload?.load;
            if (swiperLoadFunction) {
              swiperLoadFunction();
              if (!window.searchLoaded && window.handleDomReadyForSearch) {
                window.handleDomReadyForSearch();
              }
            }
          },
        });
      }
    });
  };
})();

scriptLoad({ src: `https://${staticHostName}/jsn/polyfill.js`, type: 'module' });

if (location.hostname === 'weather.joongang.co.kr') {
  document.addEventListener('DOMContentLoaded', () => {
    styleLoad({
      styleHref: `https://${staticHostName}/css/plugin/jswiper.css?v=${new Date().getTime()}`,
      tagId: 'jswiper-css',
    });

    const headerNav = document.querySelector('.header_nav.swiper-container');
    const headerClasslist = headerNav.classList;
    headerClasslist.add('jswiper', 'jaswiper_controller', 'linear', 'edge_state', 'detect_active');
    headerClasslist.remove('swiper-container');
    headerNav.querySelector('.swiper-wrapper').classList.add('jaswiper_wrap');

    scriptLoad({ src: `https://${staticHostName}/jsn/jaSwiperAutoload.js?v=${new Date().getTime()}` });
  });
}
